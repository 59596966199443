import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import confetti from 'canvas-confetti';

function App() {
  const [darkMode, setDarkMode] = useState(false);
  const [offer, setOffer] = useState('');
  const [typedText, setTypedText] = useState('');
  const [isTypingDone, setIsTypingDone] = useState(false);
  const emojiRef = useRef(null);

  const fullText = "This Domain is For Sale.";

  useEffect(() => {
    let index = 0;
    const typingSpeed = 100;

    const intervalId = setInterval(() => {
      if (index < fullText.length) {
        setTypedText(fullText.slice(0, index + 1));
        index++;
      } else {
        setIsTypingDone(true);
        clearInterval(intervalId);
      }
    }, typingSpeed);

    return () => clearInterval(intervalId);
  }, [fullText]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const handleOfferChange = (e) => {
    setOffer(e.target.value);
  };

  const sendOfferEmail = () => {
    const domain = window.location.hostname;
    const email = `offers@${domain}`;
    const subject = 'Offer to Purchase Domain';
    const body = `I would like to offer $${offer} to purchase the ${domain} domain.\n\nIt was advertised for sale here: https://${domain}`;

    window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  };

  const handleConfetti = () => {
    if (emojiRef.current) {
      const rect = emojiRef.current.getBoundingClientRect();
      confetti({
        particleCount: 48,
        spread: 60,
        angle: 50,
        origin: {
          x: (rect.left + rect.width / 2) / window.innerWidth,
          y: (rect.top + rect.height / 2) / window.innerHeight,
        },
      });
    }
  };  

  const domain = window.location.hostname;

  return (
    <div className={`app ${darkMode ? 'dark-mode' : 'light-mode'}`}>
      <div className="content-box">
        <header>
          <h1 className="title">
            {typedText}
            {!isTypingDone && <span className="cursor">|</span>}
          </h1>
        </header>
        <div className="mode-toggle">
          <button onClick={toggleDarkMode}>
            {darkMode ? 'Switch to Light Mode' : 'Switch to Dark Mode'}
          </button>
        </div>
        <main>
          <p>
            We are accepting offers for the <strong>{domain}</strong> domain
            <span
              role="img"
              aria-label="party-popper"
              ref={emojiRef}
              onClick={handleConfetti}
              style={{ cursor: 'pointer', fontSize: '1.5rem', marginLeft: '0.5rem' }}
            >
              🎉
            </span>
          </p>
          <div className="offer-container">
            <div className="input-container">
              <span className="dollar-sign">$</span>
              <input
                type="number"
                placeholder="Enter your offer"
                value={offer}
                onChange={handleOfferChange}
              />
            </div>
            <button onClick={sendOfferEmail}>Send Offer</button>
          </div>
        </main>
      </div>
    </div>
  );
}

export default App;
